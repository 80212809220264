import { Seo } from "components"
import Layout from "layout"
import * as React from "react"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Não localizada" />
    <section id="404">
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>404: Não localizada</h1>
            <p>O recurso procurado não foi localizado ou parece não existir.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
